import React from "react"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  return (
    <footer id="footer">
      <div className="inner">
        <div>Copyright © 2020 Buildit inc. all rights reserved</div>
      </div>
    </footer>
  )
}

// Footer.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Footer
