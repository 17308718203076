import React from "react"
import PropTypes from "prop-types"
import Header from "@components/header"
import Footer from "@components/footer"

const Layout = (props) => {
  const children = props.children
  const title = ``

  return (
    <>
      <div
        id="main-container">
        <Header siteTitle={title} />
        <main id="main">{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
