import PropTypes from "prop-types"
import React, { useState } from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { Link, useStaticQuery, graphql } from "gatsby"
import SearchField from '@components/main/search-field'

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      blog {
        allMenus {
          id
          name
          order
          urlLink
          parent {
            id
            name
          }
        }
      }
    }
  `)
  let allMenus = data.blog.allMenus
  let menuInfo = {}, subLevelMenuList = []
  allMenus.forEach((menu) => {
    let copied = JSON.parse(JSON.stringify(menu))
    if (copied.parent) {
      subLevelMenuList.push(copied)
    } else {
      menuInfo[copied.id] = copied
    }
  })
  subLevelMenuList.forEach((subMenu) => {
    let parent = subMenu.parent
    if (!menuInfo[parent.id].subMenus) {
      menuInfo[parent.id].subMenus = []
    }
    menuInfo[parent.id].subMenus.push(subMenu)
  })
  const menuList = Object.values(menuInfo)
  menuList.sort((a, b) => { return a.order - b.order })
  
  // [컨텐츠 생성 전]
  for (let idx = menuList.length - 1; idx >= 0 ; idx--) {
    if (menuList[idx].urlLink === '/buildthing') {
      menuList.splice(idx, 1)
    }
  }

  // Toggle Event for collapsed Navbar
  const [expanded, setExpanded] = useState(false)
  const [collapsing, setCollapsing] = useState(false)
  const onToggleCollapseBar = (expanded) => {
    if (expanded) {
      setCollapsing(true)
      setTimeout(function () {
        setCollapsing(false)
      }, 0)
    }
    setExpanded(expanded)
  }

  // Nav Dropdownlist state
  const onClickMenuItem = function () {
    document.dispatchEvent(new MouseEvent('click'))
    setExpanded(false)
  }

  return (
  <Navbar className="row" expand="lg" id="top-navbar"
    onToggle={onToggleCollapseBar}
    expanded={expanded}>
    <Navbar.Brand>
      <a href="https://buildit.kr/" target="_blank" className="link-no-style img-logo buildit">
        <span className="ic logo-buildit"></span>
      </a>
      <span className="division-line"></span>
      <a href="/" className="link-no-style img-logo blog">
        <span className="ic logo-blog"></span>
      </a>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse
      className={ (collapsing ? 'collapsing' : '')}>
      <Nav className="mr-auto">
        {
          menuList.map((menu) => {
            if (!menu.subMenus) {
              return (
                <Link
                  className="menu-item nav-link"
                  key={menu.id}
                  to={menu.urlLink}
                  state={{ currentPage: 1 }}>
                  {menu.name}
                </Link>
                // <Nav.Link
                //   className="menu-item nav-link"
                //   key={menu.id}
                //   href={menu.urlLink}
                //   onClick={clickMenuItem}>
                //   {menu.name}
                // </Nav.Link>
              )
            } else {
              return (
                <NavDropdown
                  className="menu-item"
                  key={menu.id}
                  title={menu.name}>
                  {
                    menu.subMenus.map((subMenu) => (
                      <Link
                        className="dropdown-item"
                        key={subMenu.id}
                        to={subMenu.urlLink}
                        onClick={onClickMenuItem}
                        state={{ currentPage: 1 }}>
                        {subMenu.name}
                      </Link>
                      // <NavDropdown.Item
                      //   className="dropdown-item"
                      //   key={subMenu.id}
                      //   href={subMenu.urlLink}
                      //   onClick={clickMenuItem}>
                      //   {subMenu.name}
                      // </NavDropdown.Item>
                    ))
                  }
                </NavDropdown>
              )
            }
          })
        }
      </Nav>
      <SearchField
        navbarIsExpanded={expanded}
        toggleNavbar={onToggleCollapseBar}/>
    </Navbar.Collapse>
  </Navbar>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
