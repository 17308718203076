import PropTypes from "prop-types"
import React from "react"
import TopNavbar from '@components/main/top-navbar'
// import { Link } from "gatsby"

const Header = () => (
  <header id="header">
    <TopNavbar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
