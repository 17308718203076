import React from "react"
import { Link, navigate } from "gatsby"

const getSearchKeywordUrl = function (keyword) {
  const prefixSearchUrl = `/search`
  let str = ''
  if (keyword) str = keyword
  return prefixSearchUrl + '#keyword=' + str
}

class SearchField extends React.Component {
  state = {
    searchValue: '',
    isSearchable: false,
    windowWidth: 0
  }

  updateDimensions = () => {
    if (window) {
      this.setState({ width: window.innerWidth })
    }
  }

  componentDidMount = () => {
    if (window) {
      window.addEventListener('resize', this.updateDimensions)
    }
  }

  componentWillUnmount = () => {
    if (window) {
      window.removeEventListener('resize', this.updateDimensions)
    }
  }

  onChangeInput = (event) => {
    const target = event.target
    const value = target.value
    this.setState({
      searchValue: value
    })
  }
  onSubmit = (event) => {
    let searchValue = this.state.searchValue
    event.preventDefault()
    this.props.toggleNavbar(false)
    navigate(
      getSearchKeywordUrl(searchValue),
      {
        state: { searchValue: searchValue }
      }
    )
  }

  toggleSearch = () => {
    this.setState({
      isSearchable: !this.state.isSearchable
    })
  }

  onClickSearchBtn = (e) => {
    if (!this.state.isSearchable && !this.props.navbarIsExpanded) {
      e.preventDefault()
      this.toggleSearch()
    } else {
      this.props.toggleNavbar(false)
    }
  }

  cancelSearch =  () => {
    this.setState({
      isSearchable: false,
      searchValue: ''
    })
  }
  render() {
    return (
      <form 
        className={
          "top-navbar-search-field form-inline "
          + (this.state.isSearchable ? 'show' : 'hide')
        }
        onSubmit={this.onSubmit}>
          {/* Desktop */}
          <span className={"search-tool desktop"}>
            <Link
              className="btn-search"
              to={getSearchKeywordUrl(this.state.searchValue)}
              state={{ searchValue: this.state.searchValue }}
              onClick={this.onClickSearchBtn.bind(this)}>
              <span className="ic ic-search"></span>
            </Link>
            <input
              className="search-input"
              type="text"
              placeholder="검색"
              value={this.state.searchValue}
              onChange={this.onChangeInput}
            />
            <span
              className={"ic btn-cancel-search cursor-pointer "
                + ((this.state.searchValue.length < 1 &&
                  this.state.windowWidth < 992) ? 'hide' : '')}
              onClick={this.cancelSearch}></span>
          </span>
      </form>
    )
  }
}

export default SearchField