/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import MenuMetaConfig from "@components/menu-meta-config.json"

function SEO({ schemaOrgJSONLD, isMenu, isHome, isPost, linkKey, url, description, lang, meta, title, site_name, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  // Default
  let defaultTitle = site.siteMetadata.title
  let metaTitle = defaultTitle + ' | ' + title
  let metaDescription = description || site.siteMetadata.description
  let metaKeywords= 'IoT, IoT 센서, IoT 기기, IoT 게이트웨이, 저전력 블루투스, BLE, 비콘, 비콘 센서, 비콘 업체, 비콘 회사, 소프트웨어 개발, 임베디드 시스템 개발'
  
  if (isMenu) {
    metaTitle = MenuMetaConfig[linkKey].title
    metaDescription = MenuMetaConfig[linkKey].description
    metaKeywords = MenuMetaConfig[linkKey].keywords
  }

  if (!isHome && !isPost && !isMenu) {
    metaTitle = title
  }

  // Type
  let metaType = 'website'
  if (isPost) metaType = 'article'

  // schemaOrgJSONLD
  let jsonLdStr = null
  if (schemaOrgJSONLD) jsonLdStr = JSON.stringify(schemaOrgJSONLD, null, 2)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: 'title',
          content: metaTitle
        },
        {
          name: 'subject',
          content: metaTitle
        },
        {
          name: 'keywords',
          content: metaKeywords
        },
        {
          name: `naver-site-verification`,
          content: `10c9da60f6ae4cb5d5466f4583c17353371e6257`
        },
        {
          name: 'author',
          content: '(주)빌드잇'
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:site_name`,
          content: metaTitle,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}>
      // ].concat(meta)}>
      <link rel="canonical" href={url} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {jsonLdStr ? jsonLdStr : ''}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  site_name: `Buildit 공식 블로그`,
  image: `https://blog.buildit.kr/upload-images/blog/cover.png`,
  url: `https://blog.buildit.kr/`
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  site_name: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
}

export default SEO
